import React, { FC } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const CustomBuildingDesktopIcon: FC<{ fillColor?: string } & SvgProps> = ({
  fillColor = '#212121',
  ...props
}) => (
  <Svg
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M1.75 1.5a.25.25 0 00-.25.25v12.5c0 .138.112.25.25.25H6.5v.25c0 .424.072.849.213 1.25H1.75A1.75 1.75 0 010 14.25V1.75C0 .784.784 0 1.75 0h5.5C8.216 0 9 .784 9 1.75v3c0 .138.112.25.25.25h1c.966 0 1.75.784 1.75 1.75V7h-1.5v-.25a.25.25 0 00-.25-.25h-1A1.75 1.75 0 017.5 4.75v-3a.25.25 0 00-.25-.25h-5.5zM4 3.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM3.25 7.5a.75.75 0 100-1.5.75.75 0 000 1.5zM4 9.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM5.75 4.5a.75.75 0 100-1.5.75.75 0 000 1.5zm.75 2.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm-.75 3.75a.75.75 0 100-1.5.75.75 0 000 1.5zm1.75.25A2.75 2.75 0 0110.25 8h7A2.75 2.75 0 0120 10.75v4a2.75 2.75 0 01-2.75 2.75H16v1h1.25a.75.75 0 010 1.5h-7a.75.75 0 010-1.5h1.25v-1h-1.25a2.75 2.75 0 01-2.75-2.75v-4zM13 17.5v1h1.5v-1H13zm-2.75-8c-.69 0-1.25.56-1.25 1.25v4c0 .69.56 1.25 1.25 1.25h7c.69 0 1.25-.56 1.25-1.25v-4c0-.69-.56-1.25-1.25-1.25h-7z'
      fill={fillColor}
    />
  </Svg>
)
export default CustomBuildingDesktopIcon
